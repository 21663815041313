












































import { Component, Vue } from 'vue-property-decorator';
import VMessage from '@/components/VMessage.vue';
import VButton from '@/components/VButton.vue';
import { AxiosError } from 'axios';
import Contants from '@/constants';

@Component({
  components: { VMessage, VButton }
})
export default class Login extends Vue {
  public email = '';
  public password = '';
  public loading = false;
  public errors: string[] = [];
  public incorrectLogin = false;

  public submit() {
    this.loading = true;
    this.errors = [];
    this.incorrectLogin = false;

    if (this.email.length === 0) {
      this.errors.push('Email field is required');
    }

    const re = /^[^\s@]+@[^\s@]+$/
    if (this.email.length > 0 && !re.test(this.email)) {
      this.errors.push('Email field is invalid');
    }

    if (this.password.length === 0) {
      this.errors.push('Password is required');
    }

    if (this.errors.length > 0) {
      this.loading = false;
      return;
    }

    const data = { email: this.email, password: this.password };
    this.$http.post(`${Contants.api}/auth/login`, data).then((response) => {
      this.loading = false;
      localStorage.setItem('loggedIn', 'true');
      localStorage.setItem('authToken', response.data.access_token);
      this.$http.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
      this.$router.push('/app/videos');
    }).catch((error: AxiosError) => {
      this.loading = false;
      if (error.response?.status === 401) {
        this.incorrectLogin = true;
      } else {
        this.$root.$emit('handleError', error);
      }
    });
  }
}
